import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import Select from "@components/UI/Select/Select"
import { ColProps } from "react-bootstrap/Col"
import { Option } from "../../../types/ui"
import { uniqueId } from "lodash"
import Image from "next/image"

export function Tabs({
  options,
  selectedId: selectedIdProp,
  onChange = () => {
    return
  },
  col,
  className,
}: {
  options: Option[]
  selectedId?: number | string
  onChange?: (selectedId: number | string) => void
  col?: ColProps
  className?: string
}) {
  const [selectedId, setSelectedId] = useState<number | string | undefined>(selectedIdProp)
  const [changed, setChanged] = useState<boolean>(false)

  useEffect(() => {
    if (selectedIdProp) {
      if (selectedId !== selectedIdProp) {
        setSelectedId(selectedIdProp)
      }
    }
  }, [selectedIdProp])

  useEffect(() => {
    if (selectedId && changed) {
      onChange(selectedId)
    }
  }, [selectedId, changed])

  return (
    <Col className={`tabs-cc ${className || ""}`} {...col}>
      <div className={"container-div"}>
        <div className={"tabs"}>
          {options.map((option) => {
            return (
              <div
                className={`option-tab ${option.value === selectedId ? "active" : ""}`}
                key={uniqueId()}
                onClick={() => {
                  setSelectedId(option.value)
                  setChanged(true)
                }}
              >
                {option.image && (
                  <Image
                    className={"image"}
                    src={option.image}
                    alt={option.label}
                    width={17}
                    height={17}
                    objectFit={"fill"}
                    style={{ marginRight: 5 }}
                  />
                )}
                <div className={"label"}>{option.label}</div>
              </div>
            )
          })}
        </div>
      </div>
      <Select
        value={selectedId}
        // className={"option-select"}
        tabOnly
        onChange={(id) => {
          setSelectedId(id as number)
          setChanged(true)
        }}
        options={options}
      />
    </Col>
  )
}
